/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Input, Modal, Select } from 'antd';
import MenuActions from '../../redux/middleware/menu';
import './style.scss';
import Utils from '../../redux/utils';
import { handleFloat } from '../../helper/sortData';
type Props = {
  handleDialog: any;
  open: boolean;
  modalData: any;
  onSubmit?: any;
};

const MenuModal = (props: Props) => {
  const user = Utils.getCurrentUser();
  const brand = Utils.getCurrentBrand();
  let { handleDialog, open, modalData, onSubmit } = props;
  const [blocked] = useState(true);
  const [disable] = useState(true);
  const [Profitability, setProfibility] = useState<any>(
    modalData?.final_price !== 0
      ? ((modalData?.final_price - modalData?.total_cost) /
          modalData?.final_price) *
          100
      : 0
  );
  const [suggestedPrice, setSuggestedPrice] = useState<any>();
  const [errorList, setErrorList] = useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [finalMenuPrice, setFinalMenuPrice] = useState<any>(
    modalData?.final_price !== 0 && modalData?.final_price
  );

  const array = [
    { value: 'Large', label: 'Large' },
    {
      value: 'Medium',
      label: 'Medium',
    },
    {
      value: 'Small',
      label: 'Small',
    },
  ];

  useEffect(() => {
    handleSuggestedPrice();
  }, []);

  const handleSuggestedPrice = async () => {
    let suggestedprice = modalData?.total_cost / (brand?.cost_ratio / 100);
    setSuggestedPrice(brand?.cost_ratio ? suggestedprice : 0);
  };

  const handleChangeMenuPrice = async (e: any) => {
    setFinalMenuPrice(e.target.value);
    if (
      e.target.value != 0 &&
      e.target.value !== '' &&
      !isNaN(e.target.value)
    ) {
      let profitibility =
        ((e.target.value - modalData?.total_cost) / e.target.value) * 100;
      setProfibility(profitibility);
    } else {
      setProfibility(0);
    }
  };

  const handleSubmit = async () => {
    setErrorList({});
    setErrorStatus(false);
    const params = {
      fcr: modalData.fcr,
      menu_price: modalData.final_price,
      propose_price: Number(+suggestedPrice.toString().replace(/,/g, '')),
      total_cost: handleFloat(modalData.total_cost),
      final_price: finalMenuPrice ? finalMenuPrice : '',
      profitability: handleFloat(Profitability),
      item_type: modalData.item_type,
    };

    const response = await MenuActions.updateManageMenu(
      modalData?.is_variation == 'yes'
        ? modalData?.variation_list[modalData?.variationIndex ?? 0]?.id
        : modalData?.id,
      params
    );
    if (response == undefined || !response?.success) {
      if (response != undefined) {
        setErrorList(response?.error);
        setErrorStatus(true);
      }
    } else {
      onSubmit();
    }
  };
  return (
    <Modal
      title={<div className="title font">Edit</div>}
      centered
      open={open}
      onCancel={handleDialog}
      width={608}
      footer={null}
    >
      <div className="MenuModal">
        <div className="menuSubContainer ingredientSubContainermargin">
          <div className="ingredientLabel">Recipe Name</div>
          <Input
            placeholder="Input ingredient here"
            size="large"
            disabled={blocked}
            value={modalData?.recipe_name}
          />
        </div>
        {modalData?.is_variation == 'yes' && (
          <div className="menuSubContainer">
            <div className="ingredientLabel">Variation Name</div>
            <Select
              size={'large'}
              value={
                modalData?.variation_list[modalData?.variationIndex || 0]
                  ?.variation
                  ? modalData?.variation_list[modalData?.variationIndex || 0]
                      ?.variation
                  : ''
              }
              placeholder="Select Variation"
              disabled={blocked}
              defaultValue={
                modalData?.variation_list[modalData?.variationIndex || 0]
                  ?.variation
                  ? modalData?.variation_list[modalData?.variationIndex || 0]
                      ?.variation
                  : ''
              }
              options={array}
            />
          </div>
        )}

        <div className="menuSubContainer">
          <div className="row">
            <span className="col">
              <div className="wizardLabel">Cost</div>
              <span className="pricegroup">
                <span>{brand?.currency_name}</span>
                <div className={blocked ? 'disableDiv' : ''}>
                  {' '}
                  {handleFloat(modalData?.total_cost)}
                </div>
              </span>
            </span>
            <span className="col">
              <div className="wizardLabel">Brand FCR %</div>
              <span className="pricegroup">
                <div className={blocked ? 'disableDiv' : ''}>
                  {' '}
                  {Number(brand?.cost_ratio.toFixed(2))}
                </div>
                <span>%</span>
              </span>
            </span>
          </div>
        </div>
        <div className="menuSubContainer">
          <div className="row">
            <span className="col">
              <div className="wizardLabel">Suggested Price</div>
              <span className="pricegroup">
                <span>{brand?.currency_name}</span>
                <div className={blocked ? 'disableDiv' : ''}>
                  {suggestedPrice ? handleFloat(+suggestedPrice) : 0}
                </div>
              </span>
            </span>
            <span className="col">
              <div className="wizardLabel">
                Final Menu Price <span className="sterik">*</span>
              </div>
              <span className="finalMenuPrice">
                <Input
                  placeholder="0"
                  type="number"
                  value={finalMenuPrice}
                  disabled={modalData?.status === 1}
                  onChange={(e) => handleChangeMenuPrice(e)}
                  required
                  className={
                    errorList['final_price']?.length > 0 ? 'borderRed' : ''
                  }
                />
                {errorStatus && (
                  <div className="subRecipeErrorMessages">
                    {errorList['final_price']}
                  </div>
                )}
              </span>
            </span>
          </div>
        </div>
        <div className="menuSubContainer">
          <div className="row">
            <span className="col">
              <div className="wizardLabel">Profitability %</div>
              <span className="pricegroup">
                <div className={blocked ? 'disableDiv' : ''}>
                  {' '}
                  {handleFloat(Profitability)}
                </div>
                <span>%</span>
              </span>
              {/* <span className="pricegroup">
                <span>{brand?.currency_name}</span>
                <Input
                  type="number"
                  value={handleFloat(Profitability)}
                  disabled={blocked}
                />
              </span> */}
            </span>
          </div>
        </div>
        <div className="butonGroup">
          <div className="wizard-button" onClick={() => handleDialog({})}>
            Back
          </div>
          <div
            className={`continue wizard-button ${
              +handleFloat(Profitability) < 0 && 'menu-disable-button'
            }`}
            onClick={() => +handleFloat(Profitability) >= 0 && handleSubmit()}
          >
            Submit
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MenuModal;
