/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { OrderTypeDialog } from '../index';
import { Input, Spin } from 'antd';
import useClickListener from '../../helper/outsideClickListener';
import Delete from '../../assets/images/Delete.svg';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import Utils from '../../redux/utils';
import SubMenu from '../../assets/images/SubMenu.svg';
import Eye from '../../assets/images/Eye.svg';
import Fire from '../../assets/images/Fire.svg';
import Sustainable from '../../assets/images/sustainable.png';
import './subRecipeDropdown.scss';
import { useSelector } from 'react-redux';
import { handleFloat } from '../../helper/sortData';
import DonutChart from '../../components/DonutChart';
type Props = {
  data: any;
  index: any;
  blocked?: any;
  handleIngredientObject?: any;
  handleIngredientOnChange?: any;
  deleteIngredient?: any;
  database?: any;
  component: string;
  blockedForEdit?: any;
  orderVariation?: any;
  handleIngredientOrder?: any;
  openDialog?: any;
};

const SubRecipeDropdown = (props: Props) => {
  let {
    data,
    index,
    handleIngredientObject,
    handleIngredientOnChange,
    deleteIngredient,
    blocked,
    component,
    orderVariation,
    handleIngredientOrder,
    database,
    blockedForEdit,
  } = props;
  const { currentBrand } = useSelector((state: any) => state.Auth);
  const [showListViewOne, setShowListViewOne] = useState(false);
  const [search, setSearch] = useState(data?.ingredient_name);
  const [sortIngredient, setSortIngredient] = useState<any[]>([]);
  const [spinner, setSpinner] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [blockUnitField] = useState(true);
  const [openOrderTypeDialog, setOpenOrderTypeDialog] = useState(false);
  const [orderTypeData, setOrderTypeData] = useState<any>({});
  const [error, setError] = useState(false);
  const [filterOptions] = useState([
    { key: 'A', value: 'A' },
    { key: 'B', value: 'B' },
    { key: 'C', value: 'C' },
    { key: 'D', value: 'D' },
    { key: 'E', value: 'E' },
    { key: 'F', value: 'F' },
    { key: 'G', value: 'G' },
    { key: 'H', value: 'H' },
    { key: 'I', value: 'I' },
    { key: 'J', value: 'J' },
    { key: 'K', value: 'K' },
    { key: 'L', value: 'L' },
    { key: 'M', value: 'M' },
    { key: 'N', value: 'N' },
    { key: 'O', value: 'O' },
    { key: 'P', value: 'P' },
    { key: 'Q', value: 'Q' },
    { key: 'R', value: 'R' },
    { key: 'S', value: 'S' },
    { key: 'T', value: 'T' },
    { key: 'U', value: 'U' },
    { key: 'V', value: 'V' },
    { key: 'W', value: 'W' },
    { key: 'X', value: 'X' },
    { key: 'Y', value: 'Y' },
    { key: 'Z', value: 'Z' },
  ]);
  // eslint-disable-next-line no-unused-vars
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [popUp, setPopUp] = useState<any>(null);
  const brand = Utils.getCurrentBrand();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickListener(false);
  const [selectedBrand, setSelectedBrand] = useState(currentBrand);
  const fetchIngredient = async (key: any, word: any) => {
    const data = await SubRecipeActions.getSubRecipeInventoryByCharacter(
      key,
      word,
      component == 'modifier' ? 'brand' : ''
    );
    setSortIngredient(data?.data?.items);
    setSpinner(false);
  };

  useEffect(() => {
    if (currentBrand && currentBrand?.brand_id !== selectedBrand?.brand_id) {
      fetchIngredient(database?.key, '');
      setSelectedBrand(currentBrand);
    }
  }, [currentBrand]);

  useEffect(() => {
    // if (sortIngredient?.length === 0) {
    // setSelectedFilter('A');
    // }
    if (data?.ingredient_name == '') {
      // fetchIngredient(database?.key, '');
      setSearch(data?.ingredient_name);
    }
  }, [database, data?.ingredient_name]);
  useEffect(() => {
    // const delayDebounceFn = setTimeout(() => {
    //   handleChange(search);
    // }, 500);
    // return () => clearTimeout(delayDebounceFn);
    handleChange(search);
  }, [search]);

  const handleIngredientClick = (data: any, index: number) => {
    handleIngredientObject(data, index);
    setSearch(data?.ingredient_name);
    setIsComponentVisible(!isComponentVisible);
  };

  useEffect(() => {
    setSearch(data?.ingredient_name);
  }, [data]);

  const handleClick = () => {
    setIsComponentVisible(!isComponentVisible);
    setShowListViewOne(true);
  };

  const handleChange = async (value: any) => {
    setSpinner(true);
    const filterData = await SubRecipeActions.getSubRecipeInventory(
      database?.key,
      value
      // component == 'modifier' ? 'brand' : ''
    );
    setSortIngredient(filterData?.data?.items);
    setSpinner(false);
  };

  const handleChangeName = async (e: any) => {
    setSearch(e.target.value);
    handleIngredientOnChange(e.target.value, index, 'ingredient_name');
    // handleChange(e.target.value);
  };

  const handleFilterOption = (filterOption: any) => {
    setSpinner(true);

    setSelectedFilter(filterOption?.key);
    fetchIngredient(database?.key, filterOption?.key);
  };

  // const handleSearchIngredient = async (e: string) => {
  //   setSpinner(true);
  //   const data = await SubRecipeActions.getSubRecipeInventory(database?.key, e);
  //   setSearchResults(data?.data?.items);
  //   setSpinner(false);
  // };

  // const handleChange = (e: any) => {
  //   handleIngredientOnChange(e, index, 'ingredient_name');
  //   if (!isComponentVisible) {
  //     setIsComponentVisible(true);
  //   }
  //   if (e !== '') {
  //     setShowListViewOne(false);
  //     handleSearchIngredient(search);
  //   } else {
  //     setShowListViewOne(true);
  //     setSearchResults([]);
  //   }
  // };

  // const handleSearchFunction = () => {
  //   handleChange(search);
  // };

  const handleSelectOption = (ingredient: any) => {
    handleIngredientOnChange(
      ingredient?.ingredient_name,
      index,
      'ingredient_name'
    );
    setSearch(ingredient?.ingredient_name);
    handleIngredientObject(ingredient, index);
    setIsComponentVisible(false);
  };

  const handleOrderTypeDialog = (id: any, name: any) => {
    handleIngredientOrder({
      id,
      name,
      variation_type: 'Order Type',
      variation_type_id: 3,
    });
    setOpenOrderTypeDialog(false);
  };

  const handleCancelOrderDialog = () => {
    setOpenOrderTypeDialog(false);
  };

  return component == 'subRecipe' ||
    component == 'productionRecipe' ||
    component == 'sustainability' ? (
    <div className="Ingredient ingredientContainerSpace">
      <div className="IngredientSubContainer" ref={ref}>
        <div className="subgroup" style={{ position: 'relative' }}>
          <span
            className={
              component == 'sustainability'
                ? 'sustainabilityIngredient'
                : 'Ingredient'
            }
          >
            <Input
              placeholder="Enter Ingredient "
              value={search}
              onClick={handleClick}
              // onKeyUp={() => handleSearchFunction()}
              disabled={blocked}
              onChange={(e) => {
                handleChangeName(e);
              }}
            />
          </span>

          <span className="details">
            <Input
              placeholder="0"
              type={'number'}
              value={data?.quantity ? data?.quantity : ''}
              onChange={(e) =>
                handleIngredientOnChange(+e.target.value, index, 'quantity')
              }
            />
          </span>

          <span className="details">
            <Input
              placeholder="0"
              value={data?.unit}
              disabled={blockUnitField}
              onChange={(e) =>
                handleIngredientOnChange(e.target.value, index, 'small_unit')
              }
            />
          </span>

          {component !== 'sustainability' && (
            <span className="details">
              <Input
                placeholder="0"
                type={'number'}
                value={
                  data?.waste_ingredient
                    ? handleFloat(+data?.waste_ingredient)
                    : ''
                }
                onChange={(e) =>
                  handleIngredientOnChange(
                    e.target.value,
                    index,
                    'waste_ingredient'
                  )
                }
              />
            </span>
          )}

          <span className="subDetails2">
            <span>{brand?.currency_name}</span>

            <div>
              {(Number(data?.quantity) && handleFloat(+data?.total_price)) || 0}
            </div>
          </span>
          {isComponentVisible && (
            <ul className="dropdownIngredientSubRecipe dropdown-list">
              {showListViewOne ? (
                <li className="main-list">
                  <div className="list-btn">
                    {filterOptions?.map((filterOption, index) => {
                      return (
                        <>
                          <p
                            key={index}
                            className={
                              selectedFilter === filterOption?.key
                                ? 'active'
                                : ''
                            }
                            onClick={() => handleFilterOption(filterOption)}
                          >
                            {filterOption?.key}
                          </p>
                        </>
                      );
                    })}
                  </div>
                  <ul className="subMenu">
                    <li>
                      <h4 className="placeholder">Select an option</h4>
                    </li>

                    {spinner ? (
                      <Spin
                        spinning={spinner}
                        size="default"
                        className="recipeLoader"
                      />
                    ) : (
                      sortIngredient?.map(
                        (ingredient: any, innerIndex: number) => {
                          return (
                            <li
                              key={innerIndex}
                              onClick={() =>
                                handleIngredientClick(ingredient, index)
                              }
                            >
                              <p>
                                {/* <span
                                  className={`brandIcons ${
                                    !ingredient?.brand_thumbnail
                                      ? `withPlaceholder`
                                      : ''
                                  }`}
                                >
                                  <img
                                    src={ingredient?.brand_thumbnail}
                                    width={30}
                                    alt=""
                                    className="subRecipeIcons"
                                  />
                                </span> */}
                                {ingredient?.ingredient_name}{' '}
                                {ingredient?.ingredient_category?.toLowerCase() ==
                                  'sub recipe' && (
                                  <img
                                    src={SubMenu}
                                    width={15}
                                    alt=""
                                    className="subRecipeIcons"
                                  />
                                )}
                                {ingredient?.ingredient_type?.toLowerCase() ==
                                  'sustainable' && (
                                  <img
                                    src={Sustainable}
                                    width={30}
                                    alt=""
                                    className="subRecipeIcons ingredient-sustainable-icon-space "
                                  />
                                )}
                                {/* ingredient?.verified_by == 'USA' && (
                                  <span className="optionIcon">
                                    <img
                                      src={Fire}
                                      width={5}
                                      height={10}
                                      alt=""
                                      className="iconFireWhite dropdown-verified-icon"
                                    />
                                  </span>
                                )*/}
                                {ingredient?.is_verified == 1 && (
                                  <span className="optionIcon">
                                    <img
                                      src={Fire}
                                      width={5}
                                      height={10}
                                      alt=""
                                      className="subCol1Icon dropdown-verified-icon"
                                    />
                                  </span>
                                )}
                              </p>
                            </li>
                          );
                        }
                      )
                    )}
                  </ul>
                </li>
              ) : (
                <li>
                  <ul className="searchMenu">
                    {/* {database.label !== 'USA' ? (
                      <li className="create-category">
                        <button onClick={handleCreateCategory}>
                          Create {data?.ingredient_name} as an ingredient
                        </button>
                      </li>
                    ) : null} */}
                    {spinner ? (
                      <Spin
                        spinning={spinner}
                        size="default"
                        className="loaderStyle"
                      />
                    ) : (
                      searchResults?.length > 0 &&
                      searchResults.map((searchResult, index) => {
                        return (
                          <li
                            key={index}
                            onClick={() => handleSelectOption(searchResult)}
                          >
                            <p>
                              {searchResult?.ingredient_name}{' '}
                              {searchResult?.ingredient_category?.toLowerCase() ==
                                'sub recipe' && (
                                <span className="optionIcon">
                                  <img
                                    src={SubMenu}
                                    width={15}
                                    alt=""
                                    className="subRecipeIcons"
                                  />
                                </span>
                              )}
                              {searchResult?.verified_by == 'USA' && (
                                <span className="optionIcon">
                                  <img
                                    src={Fire}
                                    width={5}
                                    height={10}
                                    alt=""
                                    className="iconFireWhite dropdown-verified-icon"
                                  />
                                </span>
                              )}
                              {searchResult?.verified_by == 'ChefAdmin' && (
                                <span className="optionIcon">
                                  <img
                                    src={Fire}
                                    width={5}
                                    height={10}
                                    alt=""
                                    className="subCol1Icon dropdown-verified-icon"
                                  />
                                </span>
                              )}
                            </p>
                          </li>
                        );
                      })
                    )}
                  </ul>
                </li>
              )}
            </ul>
          )}
        </div>
      </div>

      <img
        src={Delete}
        width={15}
        alt=""
        className={blocked ? 'hideIngredient' : 'subRecipeDeleteButon'}
        onClick={() => deleteIngredient(index)}
      />

      <div className="subrecipe-unit-container">
        <div className="subrecipe-calorie-value">
          {isNaN(data?.calories_per_unit * parseFloat(data?.quantity))
            ? 0
            : Math.round(
                Number(data?.calories_per_unit * parseFloat(data?.quantity))
              )}
        </div>
      </div>
      <div className="subrecipe-calorie-container">
        <DonutChart
          popUp={popUp}
          setPopUp={setPopUp}
          index={index}
          data={data}
          component={'wizard'}
        >
          <img
            src={Eye}
            width={20}
            className="ingredient-unmatach-select-icons"
          />
        </DonutChart>
      </div>
    </div>
  ) : component == 'modifier' ? (
    <>
      <div className="Ingredient ingredientContainerSpace modifierIngredient">
        <div className="IngredientSubContainer" ref={ref}>
          <div className="subgroup" style={{ position: 'relative' }}>
            <span className="Ingredient">
              <Input
                placeholder="Enter Ingredient"
                value={search}
                onClick={handleClick}
                // onKeyUp={() => handleSearchFunction()}
                disabled={blocked}
                onChange={(e) => {
                  handleChangeName(e);
                }}
              />
            </span>
            <span className="details modifierdetails">
              <Input
                placeholder="0"
                type={'number'}
                value={data?.quantity ? data?.quantity : ''}
                onChange={(e) =>
                  handleIngredientOnChange(+e.target.value, index, 'quantity')
                }
              />
            </span>
            <span className="details modifierdetails">
              <Input
                placeholder="0"
                value={data?.unit_name || data?.unit}
                disabled={blockUnitField}
                onChange={(e) =>
                  handleIngredientOnChange(e.target.value, index, 'unit_name')
                }
              />
            </span>
            <span className="subDetails2">
              <span>{brand?.currency_name}</span>
              <div>
                {(Number(data?.quantity) && handleFloat(+data?.total_price)) ||
                  0}
              </div>
            </span>{' '}
            <span className="details modifierdetails">
              <Input
                placeholder="0"
                type="text"
                value={data?.net_price ? data?.net_price : ''}
                onChange={(e) =>
                  handleIngredientOnChange(e.target.value, index, 'net_price')
                }
              />
            </span>
            {/* <span className="details">
              <Input
                placeholder="0"
                type={'number'}
                value={data?.min_quantity ? data?.min_quantity : ''}
                onChange={(e) => {
                  handleIngredientOnChange(
                    e.target.value,
                    index,
                    'min_quantity'
                  );
                }}
              />
            </span>
            <span className="details">
              <Input
                placeholder="0"
                type={'number'}
                disabled={!data?.min_quantity}
                value={data?.max_quantity ? data?.max_quantity : ''}
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (newValue === '' || newValue > data?.min_quantity) {
                    setError(false);
                  } else if (newValue <= data?.min_quantity) {
                    setError(true);
                  } else {
                    setError(false);
                  }
                  handleIngredientOnChange(newValue, index, 'max_quantity');
                }}
              />
            </span> */}
            {isComponentVisible && (
              <ul className="dropdownIngredientSubRecipe dropdown-list">
                {showListViewOne ? (
                  <li className="main-list">
                    <div className="list-btn">
                      {filterOptions?.map((filterOption, index) => {
                        return (
                          <>
                            <p
                              key={index}
                              className={
                                selectedFilter === filterOption?.key
                                  ? 'active'
                                  : ''
                              }
                              onClick={() => handleFilterOption(filterOption)}
                            >
                              {filterOption?.key}
                            </p>
                          </>
                        );
                      })}
                    </div>
                    <ul className="subMenu">
                      <li>
                        <h4 className="placeholder">Select an option</h4>
                      </li>

                      {spinner ? (
                        <Spin
                          spinning={spinner}
                          size="default"
                          className="recipeLoader"
                        />
                      ) : (
                        sortIngredient?.map(
                          (ingredient: any, innerIndex: number) => {
                            return (
                              <li
                                key={innerIndex}
                                onClick={() =>
                                  handleIngredientClick(ingredient, index)
                                }
                              >
                                <p>
                                  {/* <span
                                  className={`brandIcons ${
                                    !ingredient?.brand_thumbnail
                                      ? `withPlaceholder`
                                      : ''
                                  }`}
                                >
                                  <img
                                    src={ingredient?.brand_thumbnail}
                                    width={30}
                                    alt=""
                                    className="subRecipeIcons"
                                  />
                                </span> */}
                                  {ingredient?.ingredient_name}{' '}
                                  {ingredient?.ingredient_category?.toLowerCase() ==
                                    'sub recipe' && (
                                    <img
                                      src={SubMenu}
                                      width={15}
                                      alt=""
                                      className="subRecipeIcons"
                                    />
                                  )}
                                  {ingredient?.ingredient_type?.toLowerCase() ==
                                    'sustainable' && (
                                    <img
                                      src={Sustainable}
                                      width={30}
                                      alt=""
                                      className="subRecipeIcons ingredient-sustainable-icon-space "
                                    />
                                  )}
                                  {/* ingredient?.verified_by == 'USA' && (
                                  <span className="optionIcon">
                                    <img
                                      src={Fire}
                                      width={5}
                                      height={10}
                                      alt=""
                                      className="iconFireWhite dropdown-verified-icon"
                                    />
                                  </span>
                                ) */}
                                  {ingredient?.is_verified == 1 && (
                                    <span className="optionIcon">
                                      <img
                                        src={Fire}
                                        width={5}
                                        height={10}
                                        alt=""
                                        className="subCol1Icon dropdown-verified-icon"
                                      />
                                    </span>
                                  )}
                                </p>
                              </li>
                            );
                          }
                        )
                      )}
                    </ul>
                  </li>
                ) : (
                  <li>
                    <ul className="searchMenu">
                      {/* {database.label !== 'USA' ? (
                      <li className="create-category">
                        <button onClick={handleCreateCategory}>
                          Create {data?.ingredient_name} as an ingredient
                        </button>
                      </li>
                    ) : null} */}
                      {spinner ? (
                        <Spin
                          spinning={spinner}
                          size="default"
                          className="loaderStyle"
                        />
                      ) : (
                        searchResults?.length > 0 &&
                        searchResults.map((searchResult, index) => {
                          return (
                            <li
                              key={index}
                              onClick={() => handleSelectOption(searchResult)}
                            >
                              <p>
                                {searchResult?.ingredient_name}{' '}
                                {searchResult?.ingredient_category?.toLowerCase() ==
                                  'sub recipe' && (
                                  <span className="optionIcon">
                                    <img
                                      src={SubMenu}
                                      width={15}
                                      alt=""
                                      className="subRecipeIcons"
                                    />
                                  </span>
                                )}
                                {searchResult?.verified_by == 'USA' && (
                                  <span className="optionIcon">
                                    <img
                                      src={Fire}
                                      width={5}
                                      height={10}
                                      alt=""
                                      className="iconFireWhite dropdown-verified-icon"
                                    />
                                  </span>
                                )}
                                {searchResult?.verified_by == 'ChefAdmin' && (
                                  <span className="optionIcon">
                                    <img
                                      src={Fire}
                                      width={5}
                                      height={10}
                                      alt=""
                                      className="subCol1Icon dropdown-verified-icon"
                                    />
                                  </span>
                                )}
                              </p>
                            </li>
                          );
                        })
                      )}
                    </ul>
                  </li>
                )}
              </ul>
            )}
          </div>
        </div>
        <img
          src={Delete}
          width={15}
          alt=""
          className={blocked ? 'hideIngredient' : 'subRecipeDeleteButon'}
          onClick={() => deleteIngredient(index)}
        />
      </div>
      {error && <div className="error">Max quantity should be greater</div>}
    </>
  ) : (
    <div
      key={index}
      className="recipeIngredient Ingredient ingredientContainerSpace"
    >
      <OrderTypeDialog
        orderVariation={orderVariation}
        open={openOrderTypeDialog}
        handleDialogCancel={handleCancelOrderDialog}
        orderTypeData={orderTypeData}
        handleDialog={handleOrderTypeDialog}
      />
      <div className="recipeIngredientSubContainer recipe-ingredient" ref={ref}>
        <div className="group">
          <span className="recipeIngredient">
            <Input
              placeholder="Enter Ingredient "
              value={search}
              onClick={handleClick}
              // onKeyUp={() => handleSearchFunction()}
              disabled={blocked || blockedForEdit}
              onChange={(e) => {
                handleChangeName(e);
              }}
            />
          </span>
          <span className="details">
            <Input
              placeholder="0"
              type={'number'}
              value={data?.quantity ? data?.quantity : ''}
              onChange={(e) =>
                handleIngredientOnChange(+e.target.value, index, 'quantity')
              }
            />
          </span>
          <span className="details">
            <Input
              placeholder="0"
              value={data?.unit}
              disabled={blockUnitField}
              onChange={(e) =>
                handleIngredientOnChange(e.target.value, index, 'small_unit')
              }
            />
          </span>

          <span className="details2 recipe-cost">
            <span>{brand?.currency_name}</span>
            <div>
              {(Number(data?.quantity) && handleFloat(+data?.total_price)) || 0}
            </div>
          </span>
          <span
            className="details3 orderTypeTextStyle"
            onClick={() => {
              setOrderTypeData(data?.order);
              setOpenOrderTypeDialog(true);
            }}
          >
            <div
            // className={`${
            //   data?.order?.name.toLowerCase() == 'all'
            //     ? ''
            //     : 'orderTypeTextContainer'
            // }`}
            >
              {data?.order?.name}
            </div>
          </span>
        </div>
        {isComponentVisible && (
          <ul className="dropdownIngredientSubRecipe dropdown-list">
            {showListViewOne ? (
              <li className="main-list">
                <div className="list-btn">
                  {filterOptions.map((filterOption, index) => {
                    return (
                      <>
                        <p
                          key={index}
                          className={
                            selectedFilter === filterOption?.key ? 'active' : ''
                          }
                          onClick={() => handleFilterOption(filterOption)}
                        >
                          {filterOption?.key}
                        </p>
                      </>
                    );
                  })}
                </div>
                <ul className="subMenu">
                  <li>
                    <h4 className="placeholder">Select an option </h4>
                  </li>

                  {spinner ? (
                    <Spin
                      spinning={spinner}
                      size="default"
                      className="recipeLoader"
                    />
                  ) : (
                    sortIngredient?.map(
                      (ingredient: any, innerIndex: number) => {
                        return (
                          <li
                            key={innerIndex}
                            onClick={() =>
                              handleIngredientClick(ingredient, index)
                            }
                          >
                            <p>
                              {/* <span
                                className={`brandIcons ${
                                  !ingredient?.brand_thumbnail
                                    ? `withPlaceholder`
                                    : ''
                                }`}
                              >
                                <img
                                  src={ingredient?.brand_thumbnail}
                                  width={15}
                                  alt=""
                                  className="subRecipeIcons"
                                />
                              </span> */}
                              {ingredient?.ingredient_name}
                              {ingredient?.ingredient_category?.toLowerCase() ==
                                'sub recipe' && (
                                <span className="optionIcon">
                                  <img
                                    src={SubMenu}
                                    width={15}
                                    alt=""
                                    className="subRecipeIcons"
                                  />
                                </span>
                              )}
                              {ingredient?.ingredient_type?.toLowerCase() ==
                                'sustainable' && (
                                <img
                                  src={Sustainable}
                                  width={30}
                                  alt=""
                                  className="subRecipeIcons ingredient-sustainable-icon-space "
                                />
                              )}
                              {/*ingredient?.verified_by == 'USA' && (
                                <span className="optionIcon">
                                  <img
                                    src={Fire}
                                    width={5}
                                    height={10}
                                    alt=""
                                    className="iconFireWhite dropdown-verified-icon"
                                  />
                                </span>
                              )*/}
                              {ingredient?.is_verified == 1 && (
                                <span className="optionIcon">
                                  <img
                                    src={Fire}
                                    width={5}
                                    height={10}
                                    alt=""
                                    className="subCol1Icon dropdown-verified-icon"
                                  />
                                </span>
                              )}
                            </p>
                          </li>
                        );
                      }
                    )
                  )}
                </ul>
              </li>
            ) : (
              <li>
                <ul className="searchMenu">
                  {/* {database.label !== 'USA' ? (
                    <li className="create-category">
                      <button onClick={handleCreateCategory}>
                        Create {data?.ingredient_name} as an ingredient
                      </button>
                    </li>
                  ) : null} */}
                  {spinner ? (
                    <Spin
                      spinning={spinner}
                      size="default"
                      className="loaderStyle"
                    />
                  ) : (
                    searchResults?.length > 0 &&
                    searchResults.map((searchResult, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() => handleSelectOption(searchResult)}
                        >
                          <p>
                            {searchResult?.ingredient_name}{' '}
                            {searchResult?.ingredient_category.toLowerCase() ==
                              'sub recipe' && (
                              <img
                                src={SubMenu}
                                width={15}
                                alt=""
                                className="subRecipeIcons"
                              />
                            )}
                            {searchResult?.verified_by == 'USA' && (
                              <span className="optionIcon">
                                <img
                                  src={Fire}
                                  width={5}
                                  height={10}
                                  alt=""
                                  className="iconFireWhite dropdown-verified-icon"
                                />
                              </span>
                            )}
                            {searchResult?.verified_by == 'ChefAdmin' && (
                              <span className="optionIcon">
                                <img
                                  src={Fire}
                                  width={5}
                                  height={10}
                                  alt=""
                                  className="subCol1Icon dropdown-verified-icon"
                                />
                              </span>
                            )}
                          </p>
                        </li>
                      );
                    })
                  )}
                </ul>
              </li>
            )}
          </ul>
        )}
      </div>

      <img
        src={Delete}
        width={15}
        alt=""
        onClick={() => deleteIngredient(index)}
        className={
          blocked || blockedForEdit
            ? 'hideIngredient'
            : 'deleteIngredientForRecipe'
        }
      />
      <div className="subrecipe-unit-container recipeIngredient-calorie-value">
        <div className="subrecipe-calorie-value">
          {isNaN(data?.calories_per_unit * parseFloat(data?.quantity))
            ? 0
            : Math.round(
                Number(data?.calories_per_unit * parseFloat(data?.quantity))
              )}
        </div>
      </div>
      <div className="subrecipe-calorie-container recipeIngredient-graph">
        <DonutChart
          popUp={popUp}
          setPopUp={setPopUp}
          index={index}
          data={data}
          component={'wizard'}
        >
          <img
            src={Eye}
            width={20}
            className="ingredient-unmatach-select-icons"
          />
        </DonutChart>
      </div>
    </div>
  );
};

export default SubRecipeDropdown;
